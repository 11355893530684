import React, { useEffect, useState, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GET_EMAIL_BY_ID,
  QUERY_CLIENT_PHOTOS,
} from "../../../../utils/GraphQL/queries";
import "./emailModal.css";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Snackbar } from "@mui/material";
import { Buffer } from "buffer";
// import { copyImageToClipboard } from "copy-image-clipboard";
import copyImageToClip from "../../../../utils/copyImageToClip";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ImageModal from "../ImageModal/ImageModal";

const EmailModal = ({ emailId, closeEmail, prepareReply, changeEmail }) => {
  const [dateFormat, setDateFormat] = useState("");
  // const [addedCopyListeners, setAddedCopyListeners] = useState(false);
  const [imgIds, setImgIds] = useState([]);
  // const [btnIdsForCopy, setBtnIdsForCopy] = useState([]);
  // const [imgIdsForCopy, setImgIdsForCopy] = useState([]);
  const htmlRef = useRef();
  const [loadEmail, { loading: emailLoading }] = useLazyQuery(GET_EMAIL_BY_ID);
  const [getIncImgIdData, { loading: imageLoading }] =
    useLazyQuery(QUERY_CLIENT_PHOTOS);
  const [imgData, setImgData] = useState([]);
  const [email, setEmail] = useState({});
  const [emailChain, setEmailChain] = useState([]);
  const [newerEmailId, setNewerEmailId] = useState("");
  const [attachImgs, setAttachImgData] = useState([]);
  const [attachPdf, setAttachPdf] = useState([]);
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
    duration: 2000,
  });
  const [imageModal, setImageModal] = useState({
    open: false,
    src: "",
  });

  useEffect(() => {
    getImgData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgIds]);

  useEffect(() => {
    setEmail({});
    setEmailChain([]);
    fetchEmail(emailId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  useEffect(() => {
    const chainCombined = [{ ...email }, ...emailChain];
    let imgObjArr = [];
    let pdfArr = [];

    chainCombined?.forEach((obj) => {
      if (obj?.attachImgs?.length > 0) {
        for (let index = 0; index < obj?.attachImgs?.length; index++) {
          const element = obj?.attachImgs[index];
          imgObjArr?.push(element);
        }
      }

      if (obj?.attachPdf?.length > 0) {
        for (let index = 0; index < obj?.attachPdf?.length; index++) {
          const element = obj?.attachPdf[index];
          pdfArr?.push(element);
        }
      }
      return;
    });

    if (pdfArr?.length > 0) {
      setAttachPdf([...pdfArr]);
    }

    if (imgObjArr?.length > 0) {
      const addedOriginal = [...imgObjArr];
      const updated = addedOriginal.map((arr) => {
        return { ...arr, originalData: arr?.imageData };
      });

      setAttachImgData([...updated]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, emailChain]);

  useEffect(() => {
    setImgIds([]);
    if (email?.html || email?.emailText) {
      filterImgIdsInHtml();
    }

    let date = "";
    const getNum = parseInt(email?.dateCreated);
    if (getNum > 0) {
      date = BAGroupTimeFormat(getNum, true);
    }
    setDateFormat(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const rotateImg = (index, rotation, attachedBool) => {
    const next = index + 1;
    const rot = rotation + 90;

    let img = new Image();
    img.src = attachedBool
      ? attachImgs[index]?.originalData
      : imgData[index]?.originalData;

    img.onload = () => {
      let canvas = document.createElement("canvas");

      const wide = rot === 90 || rot === 270 ? img.height : img.width;
      const high = rot === 90 || rot === 270 ? img.width : img.height;

      canvas.width = wide;
      canvas.height = high;
      let ctx = canvas.getContext("2d");

      const drawH = rot === 90 || rot === 270 ? high : wide;
      const drawW = rot === 90 || rot === 270 ? wide : high;

      ctx.translate(wide / 2, high / 2);
      ctx.rotate(rot * (Math.PI / 180));
      ctx.drawImage(img, -drawH / 2, -drawW / 2);

      if (attachedBool) {
        setAttachImgData((prev) => [
          ...prev.slice(0, index),
          {
            ...prev[index],
            imageData: canvas.toDataURL("image/png", 1),
            rotation: rot,
          },
          ...prev.slice(next),
        ]);

        return;
      }

      if (!attachedBool) {
        setImgData((prev) => [
          ...prev.slice(0, index),
          {
            ...prev[index],
            imageData: canvas.toDataURL("image/png", 1),
            rotation: rot,
          },
          ...prev.slice(next),
        ]);
      }
    };
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
      duration: 2000,
    });
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms || 2000,
    });
  };

  const handleImgModalClose = () => {
    setImageModal(prev => ({
      open: false,
      src: prev?.src
    }));
  };

  const filterImgIdsInHtml = () => {
    let html = email?.html || "";
    let text = email?.emailText || "";

    const regexBGWhite =
      /((background:|backgroundColor:|background-color:)\s?(white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\));?)/gi;

    html = html.replace(
      regexBGWhite,
      "background: #525151; color: #fff !important;"
    );
    text = text.replace(
      regexBGWhite,
      "background: #525151; color: #fff !important;"
    );

    const htmlArr = html?.split(" ") || [];
    const textArr = text?.split(" ") || [];

    htmlArr.forEach((word) => {
      const idRegEx = /[a-f\d]{24}/i;
      if (idRegEx.test(word)) {
        setImgIds((prev) => {
          const newIds = prev.filter((id) => id !== word);
          return [...newIds, word];
        });
      }
      return;
    });

    textArr.forEach((word) => {
      const idRegEx = /[a-f\d]{24}/i;
      if (idRegEx.test(word)) {
        setImgIds((prev) => {
          const newIds = prev.filter((id) => id !== word);
          return [...newIds, word];
        });
      }
      return;
    });

    htmlRef.current.innerHTML = htmlArr.join(" ") || textArr.join(" ");
  };

  const getImgData = async () => {
    const { data } = await getIncImgIdData({
      variables: { pictureIdArr: imgIds },
    });

    if (data?.getImageById?.length > 0) {
      const addedOriginal = [...data?.getImageById];
      const updated = addedOriginal.map((arr) => {
        return { ...arr, originalData: arr?.imageData };
      });

      setImgData([...updated]);
    }
  };

  const fetchEmail = async (id) => {
    const { data } = await loadEmail({
      variables: {
        emailId: id,
      },
      fetchPolicy: "network-only",
    });


    if (data?.getEmailById?.currentEmail) {
      setEmail({ ...data?.getEmailById?.currentEmail });
    }

    if (data?.getEmailById?.emailChain?.length > 0) {
      //set bool if there are older or newer emails
      const olderEmails = [];
      const hasNewer = data?.getEmailById?.emailChain?.filter((item) => {
        if (
          parseInt(item?.dateCreated) <
          parseInt(data?.getEmailById?.currentEmail?.dateCreated)
        ) {
          olderEmails?.push(item);
          return null;
        }
        return true;
      });

      if (hasNewer?.length > 0) {
        setNewerEmailId(data?.getEmailById?.emailChain[0]?.id);
      } else {
        setNewerEmailId("");
      }

      setEmailChain([...olderEmails]);
    }

    if (data?.getEmailById?.emailChain?.length === 0) {
      setEmailChain([]);
      setNewerEmailId("");
    }
  };

  return (
    <>
      {(emailLoading || imageLoading) && (
        <AdminBDRSpinner message={"Loading..."} />
      )}
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <ImageModal
        modalOpen={imageModal?.open}
        handleClose={handleImgModalClose}
        imgSrc={imageModal?.src}
      />
      <div
        className="email-modal-container"
        onClick={() => {
          closeEmail();
        }}
      >
        <div
          className="email-modal-content"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <button className="email-modal-close" onClick={closeEmail}>
            Close
          </button>
          <ul className="email-modal-list-older">
            <li className="email-modal-list-hdr-bg">
              <div>
                <span className="green-color-text">From:</span> {email?.from}
              </div>
              <div>
                <span className="green-color-text">Subject:</span>{" "}
                {email?.emailSubject}
              </div>
              <span>
                <span className="green-color-text">To:</span> {email?.to}
              </span>
              <span>
                <span className="green-color-text">Date:</span> {dateFormat}
              </span>
              <div>
                <button
                  className="email-modal-button"
                  onClick={() => {
                    prepareReply(email);
                  }}
                >
                  Reply
                </button>
                {newerEmailId && (
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      changeEmail(newerEmailId);
                    }}
                    className="email-modal-older-btn"
                  >
                    View Newer Email
                  </button>
                )}
              </div>
            </li>
            <li ref={htmlRef} className="email-modal-body-html"></li>
          </ul>
          {/* {olderEmails && (
            <button
              onClick={(event) => {
                event.preventDefault();
                document
                  .querySelector("#first-of-older-chain")
                  ?.scrollIntoView({ behavior: "smooth" });
              }}
              className="email-modal-older-btn"
            >
              View Older Emails
            </button>
          )} */}
          {emailChain.length > 0 &&
            emailChain?.map((emailObj, index) => {
              const id =
                index === 0
                  ? "first-of-older-chain"
                  : `${index}-of-older-chain`;

              let date = "";
              const getNum = parseInt(emailObj?.dateCreated);
              if (getNum > 0) {
                date = BAGroupTimeFormat(getNum, true);
              }

              return (
                <ul
                  id={id}
                  key={`email-chain-id-${emailObj?.id}`}
                  className="email-modal-list-older"
                >
                  <li className="email-modal-list-older-hdr email-modal-list-hdr-bg">
                    <span>
                      <span className="green-color-text">Date:</span> {date}
                    </span>
                  </li>
                  <li
                    dangerouslySetInnerHTML={{ __html: emailObj?.html }}
                    className="email-modal-body-html"
                  ></li>
                </ul>
              );
            })}

          <div className="email-modal-images-container">
            {imgData &&
              imgData?.map((item, index) => {
                const imgId = "imgEl" + item._id;
                const btnId = "btnEl" + item._id;

                const html = (
                  <div
                    key={item._id + index}
                    className="email-modal-images-box"
                  >
                    <div className="email-modal-img-overlay-box">
                      <button
                        className="email-modal-copy-btn"
                        id={btnId}
                        onPointerUp={async () => {
                          const img = document.querySelector(`#${imgId}`);
                          const blob = new ClipboardItem({
                            "image/png": copyImageToClip(img.src),
                          });

                          navigator.clipboard.write([blob]);
                          handleSnack("Image copied", 1500);
                        }}
                      >
                        <ContentCopyIcon
                          titleAccess="Copy Image"
                          fontSize="inherit"
                        />
                      </button>
                      <button
                        className="email-modal-action-img-button"
                        onClick={() => {
                          const rotation =
                            item?.rotation === 360 ? 0 : item?.rotation || 0;
                          rotateImg(index, rotation, false);
                        }}
                      >
                        <RotateRightIcon
                          titleAccess="Rotate Image"
                          fontSize="inherit"
                        />
                      </button>{" "}
                    </div>
                    <img
                      id={imgId}
                      className="email-modal-img"
                      src={item?.imageData}
                      alt={`door`}
                      onClick={() => {
                        setImageModal({
                          open: true,
                          src: item?.imageData,
                        });
                      }}
                    />
                  </div>
                );

                return html;
              })}
            {(attachImgs?.length > 0 || attachPdf?.length > 0) && (
              <>
                {attachImgs?.length > 0 &&
                  attachImgs?.map((item, index) => {
                    const imgId = "attImgEl" + item._id;
                    const btnId = "attBtnEl" + item._id;

                    const html = (
                      <div
                        key={item._id + index}
                        className="email-modal-images-box"
                      >
                        <div className="email-modal-img-overlay-box">
                          <button
                            className="email-modal-copy-btn"
                            id={btnId}
                            onPointerUp={async () => {
                              const img = document.querySelector(`#${imgId}`);
                              const blob = new ClipboardItem({
                                "image/png": copyImageToClip(img.src),
                              });

                              navigator.clipboard.write([blob]);
                              handleSnack("Image copied", 1500);
                            }}
                          >
                            <ContentCopyIcon
                              titleAccess="Copy Image"
                              fontSize="inherit"
                            />
                          </button>
                          <button
                            className="email-modal-action-img-button"
                            onClick={() => {
                              const rotation =
                                item?.rotation === 360
                                  ? 0
                                  : item?.rotation || 0;
                              rotateImg(index, rotation, true);
                            }}
                          >
                            <RotateRightIcon
                              titleAccess="Rotate Image"
                              fontSize="inherit"
                            />
                          </button>{" "}
                        </div>
                        <img
                          id={imgId}
                          className="email-modal-img"
                          src={item?.imageData}
                          alt={`door pic ${index}`}
                          onClick={() => {
                            setImageModal({
                              open: true,
                              src: item?.imageData,
                            });
                          }}
                        />
                      </div>
                    );

                    return html;
                  })}
                {attachPdf?.length > 0 &&
                  attachPdf?.map((item, index) => {
                    const html = (
                      <div
                        key={item?.id + index}
                        className="email-modal-pdf-box"
                      >
                        <div>
                          <button
                            className="email-modal-copy-btn"
                            onClick={() => {
                              const destructData = item?.fileData.split(";");
                              // base64 string
                              const base64str = destructData[1].split(",")[1];
                              const pdfData = Buffer.from(base64str, "base64");

                              // create the blob object with content-type "application/pdf"
                              let blob = new Blob([pdfData], {
                                type: "application/pdf",
                              });
                              let url = URL.createObjectURL(blob);

                              window.open(url);
                            }}
                          >
                            View PDF {index + 1}
                          </button>
                        </div>
                      </div>
                    );

                    return html;
                  })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailModal;
